import React from 'react'
import kw from '../locales/keywords'
import { RequestContext, Text } from '@botonic/react'

function shuffle(o) {
  for (
    var j, x, i = o.length;
    i;
    j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
  );
  return o
}
function initNumbers() {
  let numbers = [1, 2, 3, 4]
  return shuffle(numbers)
}

export default class extends React.Component {
  static contextType = RequestContext
  static async botonicInit({ input, session, params, lastRoutePath }) {
    if (session.counter || session.counter == 1) {
      if (session.counter == 4) {
        session.numbers = initNumbers()
        session.counter = 1
      } else {
        session.counter++
      }
    } else {
      session.numbers = initNumbers()
      session.counter = 1
    }

    let random = {
      '1':
        'Deixi el seu telèfon i els nostres agents es posaran... Ja ja ja. És broma, mai el trucarem.',
      '2':
        "No puc respondre-li. M'han programat per a respondre només a gent que escriu sense faltes de ortografia.",
      '3':
        'Ha excedit el nombre de preguntes gratuïtes. Facis premium! Als robots també ens agrada anar de compres.',
      '4': "És vostè molt pesat. Surti al carrer i busqui's una vida."
    }
    return { random_resp: random[session.numbers[session.counter - 1]] }
  }

  render() {
    this.context.setLocale('ca')
    let _ = this.context.getString
    let input = this.context.input.data
    if (input.match(kw.millor_programa)) {
      return <Text>{_('millor_programa')}</Text>
    } else if (input.match(kw.presentador_divertit)) {
      return <Text>{_('presentador_divertit')}</Text>
    } else if (input.match(kw.rodalies)) {
      return <Text>{_('rodalies')}</Text>
    } else if (input.match(kw.gracies)) {
      return <Text>{_('gracies')}</Text>
    } else {
      return <Text>{this.props.random_resp}</Text>
    }
  }
}
