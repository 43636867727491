export default {
  millor_programa: ['Control T, per descomptat.'],
  presentador_divertit: ['No hi ha dubte: Tomás Molina.'],
  rodalies: ['Jo em compraria una bici.'],
  gracies: ['Tens el telèfon de Tomás Molina?'],
  random: [
    'Deixi el seu telèfon i els nostres agents es posaran... Ja ja ja. És broma, mai el trucarem.',
    "No puc respondre-li. M'han programat per a respondre només a gent que escriu sense faltes de ortografia.",
    'Ha excedit el nombre de preguntes gratuïtes. Facis premium! Als robots també ens agrada anar de compres.',
    "És vostè molt pesat. Surti al carrer i busqui's una vida."
  ]
}
